.NavbarContainer {
  background-color: white;
  height: 60px;
  width: 100%;
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 8px 0px 20px rgba(0, 0, 0, 0.3);
}
.listitem {
  margin-right: 3%;
  margin-right: 20px;
}

.NavbarLeftdiv {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
}

.company_logo {
  height: 40px;
  width: 169px;
  margin: 0 0 0 2%;
  margin-top: 3px;
  margin-bottom: 0;
}

.Navbar_anchor {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 18px;
  padding: 20px 5px;
}

.Navbar_anchor:hover {
  color: #636ff8;
}

.Navbar_ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.NavbarRightdiv_select {
  font-weight: lighter;
  text-align: center;
  border-radius: 3%;
}

.NavbarRightdiv {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
}

.myaccount_dropdown {
  height: 130px;
  width: 200px;
  z-index: 999;
  background: rgba(255, 255, 255);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  position: absolute;
  top: calc(60px);
  transform: translateX(-25%);
  box-shadow: 1px 1px 4px black;
}
.myaccount_dropdown-height {
  height: 90px;
  width: 200px;
  z-index: 999;
  background: rgba(255, 255, 255);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  position: absolute;
  top: calc(60px);
  transform: translateX(-25%);
  box-shadow: 1px 1px 4px black;
}

.myaccount-dropdown {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 5px 10px 5px;
  background: rgb(174, 232, 253);
  border-radius: 8px;
}

.myAccountLinks {
  color: rgb(0, 0, 0);
  font-size: medium;
  border: none;
  background: none;
  font-weight: 600;
}

.myAccountLinks:hover {
  color: rgb(82, 63, 255);
}

.myOrdersDiv {
  border-top: 1px rgb(208, 206, 206) solid;
  border-bottom: 1px rgb(208, 206, 206) solid;
  padding: 2% 5%;
}

.MyAccountorDiv {
  border: 2px solid rgb(32, 35, 227);
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  width: 25px;
  height: 25px;
  color: rgb(32, 35, 227);
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .Navbar_anchor {
    font-size: 11px;
  }
}

.Homelogout-btn {
  font-size: 16px;
  width: 100%;
  height: 25px;
  color: rgb(0, 0, 0);
  border-radius: 4px;
  padding: 5px 5px 5px 16px;
  line-height: 0.2;
  letter-spacing: 0.25px;
  text-align: left;
}
