.page_404 {
  padding: 40px 0;
  background: #fff;
}

.page_404-container {
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.page_404-img {
  height: 375px;
  width: 100%;
}

.four_zero_four_bg {
  height: 400px;
  background-position: center;
}

.four_zero_four_bg-h3 {
  font-size: 35px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 95px;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}
