.textInput {
  padding: 0 0 0 10px;
  border: 1px solid rgb(201, 200, 200);
  border-radius: 5px;
}

.textInput:focus {
  outline: 2px solid rgb(230, 230, 230);
}

.textInput:hover {
  box-shadow: 0 0 3px grey;
}

.App {
  display: grid;
  min-height: 100vh;
  overflow: hidden;
  font-family: "Lato", "museo_sans300", Arial, sans-serif;
}

.listitem {
  margin-right: 3%;
  margin-right: 20px;
}

.company_logo {
  width: 169px;
  margin: 0 0 0 2%;
  margin-top: 3px;
  margin-bottom: 0;
}

.Navbar_anchor {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 18px;
}

.Navbar_anchor:hover {
  color: #636ff8;
  cursor: pointer;
}

.Navbar_ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.NavbarRightdiv_select {
  font-weight: lighter;
  text-align: center;
  border-radius: 3%;
}

.NavbarRightdiv {
  display: flex;
}

.MyAccount {
  display: flex;
  flex-direction: row;
  margin-top: 7%;
  margin-bottom: 150px;
}

.log {
  text-align: center;
}

.App_footer {
  text-align: center;
  background-color: rgb(241, 240, 240);
  padding: 5px;
}

.globalButton {
  background-color: #ac1f26;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.2s;
  padding: 10px;
  width: 100px;
}

.globalButton:hover {
  background-color: #ac1f26;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .company_logo {
    width: 120px;
  }

  .Navbar_anchor {
    font-size: 13px;
  }
}

.side-nav-bar-icon {
  color: #ffffff;
  font-size: 20px;
  margin: 0px 0px 0px 4px;
}

.close-btn {
  font-size: 16px;
  height: 35px;
  min-width: 80px;
  border: 2px solid #ad2128;
  color: black;
  border-radius: 4px;
  background: none;
}

.close-btn:hover {
  box-shadow: 0 3px 5px rgb(40 36 51 / 50%);
  cursor: pointer;
}

.Modal-btn {
  font-size: 16px;
  background: #ad2128;
  border: 2px solid #ad2128;
  height: 35px;
  min-width: 80px;
  color: white;
  border-radius: 4px;
  font-weight: 400;
  margin: 10px;
  border: none;
}

.Modal-btn:hover {
  box-shadow: 0 3px 5px rgb(40 36 51 / 50%);
  cursor: pointer;
}

.Modal-btn:disabled {
  background-color: #82b1cd;
  color: white;
  box-shadow: none;
  opacity: 0.1;
}

.modal-open-btn {
  font-size: 16px;
  background: #ad2128;
  height: 35px;
  width: 100px;
  border: 0px solid #ad2128;
  color: white;
  border-radius: 4px;
  padding: 6px;
  line-height: 0.2;
  letter-spacing: 0.25px;
  margin: 0.3rem;
}

.modal-open-btn:hover {
  box-shadow: 0 3px 5px rgb(40 36 51 / 50%);
  cursor: pointer;
}

.table-scroll {
  border: 3px solid #f9cbb5;
  border-radius: 10px;
}

.modal {
  z-index: 111 !important;
  display: block !important;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  width: 25% !important;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 999;
}

.header-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  color: black;
  background-color: #f9cbb5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 16px;
}

.header-title {
  margin: 0;
}

.footer-modal {
  color: white;
  padding: 15px 0px 15px 0px;
  display: flex;
  justify-content: center;
}

.modalCheckbox {
  margin: 0 25px 0 15px;
}

.status_stock_checkBoxes {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  flex-wrap: wrap;
}

.label-class {
  color: #546271;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  width: 90%;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #e7e5ea;
  border-radius: 5px;
}

.aster-risk-Icon {
  position: relative;
  bottom: 5px;
  color: red;
  font-size: 5px;
}

.MuiPaper-root-MuiAlert-root {
  color: #fff !important;
  background-color: green !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}

.MuiAlert-root {
  color: #fff !important;
  background-color: rgb(46, 125, 50) !important;
}

.css-14ewny {
  color: #fff !important;
  background-color: rgb(46, 125, 50) !important;
}

.tss-tlx3x1-MUIDataTableToolbar-root {
  background: #f9cbb5;
}

.MuiBox-root-27 {
  border-bottom: 3px solid #e9ebec;
}

.MuiBox-root-2 {
  border-bottom: 3px solid #eeeeee !important;
}

.MuiBox-root-15 {
  border-bottom: 1px solid #eeeeee !important;
}

.MuiTabs-root {
  width: fit-content;
  border-radius: 15px;
  border: 1px solid #eeeeee;
  display: flex;
  overflow: hidden;
  margin-left: 20px;
  margin-top: 10px;
  -webkit-overflow-scrolling: touch;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  background: #312646;
}

.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
}

.add_button_container {
  text-align: end;
}

.tableEditButton {
  border: none;
  background: none;
  cursor: pointer;
}

.formbutton {
  margin: 10px;
  float: right;
  display: flex;
}

@media only screen and (min-width: 769px) {
  .status-div {
    margin: 0 0 0 10px;
    display: flex;
  }

  .productModal {
    z-index: 111 !important;
    display: block !important;
    position: fixed;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 50% !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .status-div {
    margin: 0 0 0 10px;
    display: flex;
  }

  .css-11mde6h-MuiPaper-root {
    width: 689px !important;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 35% !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 50%;
    position: absolute !important;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

@media only screen and (max-width: 820px) {
  .status-div {
    margin: 0 0 0 10px;
    display: flex;
  }

  .status-main-contract {
    display: block !important;
    margin: 5px;
  }

  .add-contracts-btn {
    font-size: 16px;
    background: #48336e;
    height: 25px;
    width: 100px;
    border: 0px solid #48336e;
    color: white !important;
    border-radius: 4px;
    float: right;
    margin-top: 100px;
    margin-right: 53px;
    white-space: nowrap;
  }

  .css-11mde6h-MuiPaper-root {
    width: 735px !important;
  }

  .table-scroll {
    border: 3px solid #f9cbb5;
    border-radius: 10px;
  }

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 35% !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
}

.header-title {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}

.MUIDataTableHeadCell-data {
  font-size: 10px !important;
}

.MuiTablePagination-displayedRows {
  font-size: 10px !important;
}

.MuiTypography-root {
  font-size: 14px !important;
  font-weight: bold !important;
}

.MuiToolbar-root {
  min-height: 20px !important;
}

.MuiSvgIcon-root {
  font-size: 15px !important;
}

.next-input.next-large input {
  font-size: 13px !important;
}

.editor-header-label {
  margin: 5px;
  font-size: 16px;
  font-weight: 800;
  color: #546271;
}

.MuiSelect-select {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.MuiMenuItem-root {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.tableHeader {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.toaststyle {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.css-1dimb5e-singleValue {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.MUIDataTableHeadCell-fixedHeader {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #48464c !important;
}

.MuiFormControlLabel-root {
  margin: 0 !important;
}

.menuicon {
  color: rgb(255, 255, 255);
  font-size: 30px;
  margin: 0px;
  width: 20px;
  height: 20px;
}

.fileNameDisplay {
  font-size: 12px;
  font-weight: 400;
  color: #546271;
  padding: 5px 0 0 0;
}

.css-11mde6h-MuiPaper-root {
  box-shadow: none !important;
}

.aster-risk-Icon-amend-termination {
  position: relative;
  left: 190px;
  bottom: 18px;
  color: red;
  font-size: 5px;
}

.alert-danger {
  color: red;
  font-weight: 300;
  font-size: 12px;
}

input:-webkit-autofill {
  transition: background-color 1s ease-in 2000s;
}

.active-link {
  color: #ffd255;
  margin: 0px 0px 0px 4px;
  font-size: 25px;
}

.checkBox_label-class {
  color: #546271;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
}

.modal-checkbox-div {
  display: flex;
  justify-content: left;
  padding: 5px 10px 0 0;
}

.Collapsible__contentInner {
  display: inherit;
  overflow-y: scroll;
}
