.parenthome {
  position: relative;
  text-align: center;
  color: #ffffff;
  width: 100%;
}

.Home {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  color: black;
  width: 100%;
  flex-wrap: wrap;
}

.elementImage {
  border-radius: 6px 6px 0 0;
  width: 100%;
  height: 180px;
}

.productFamilyCard {
  background: none;
  width: 75%;
  height: 265px;
  display: inline-block;
  border-radius: 8px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 15px;
}

.productFamilyCard:hover {
  box-shadow: 0px 0px 4px 2px rgb(255, 255, 255, 50%);
}

.element-container {
  position: relative;
  display: inline-block;
  margin: 1% auto;
  width: 350px;
}

.home_ribbon {
  z-index: 40;
  height: 188px;
  position: absolute;
  margin-bottom: 30px;
  background: url(https://html5book.ru/wp-content/uploads/2015/10/snow-road.jpg);
  background-size: cover;
  text-transform: uppercase;
  color: white;
}

.home_ribbon3 {
  width: 150px;
  height: 30px;
  line-height: 30px;
  padding-left: 15.5px;
  position: absolute;
  left: -9px;
  top: 20px;
  background: red;
}

.home_ribbon3:before,
.home_ribbon3:after {
  content: "";
  position: absolute;
}

.home_ribbon3:before {
  height: 0;
  width: 0;
  top: -8.5px;
  left: 0.1px;
  border-bottom: 9px solid black;
  border-left: 9px solid transparent;
}

.home_ribbon3:after {
  height: 0;
  width: 0;
  right: -14.5px;
  border-top: 15.5px solid transparent;
  border-bottom: 15.5px solid transparent;
  border-left: 15.5px solid red;
}

.parenthome_Header {
  font-size: 36px;
  margin: 2% 0 1% 0;
  padding: 0%;
}

.parenthome_sub_heading {
  font-size: 15px;
  margin: 0%;
  padding: 0%;
  font-weight: 500;
}

.parenthome_Header_2 {
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .parenthome_Header,
  .parenthome_sub_heading,
  .parenthome_Header_2 {
    font-size: 11px;
  }
}

.parenthome_Header {
  font-size: 36px;
  margin: 2% 0 1% 0;
  padding: 0%;
}

.parenthome_sub_heading {
  font-size: 15px;
  margin: 0%;
  padding: 0%;
  font-weight: 500;
}
.main-container {
  background-image: url("../src/assets/images/_tech_support.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  margin: 5px;
}

@media only screen and (max-width: 600px) {
  .parenthome_Header,
  .parenthome_sub_heading,
  .parenthome_Header_2 {
    font-size: 16px;
  }
}
