.customer-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 30% !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    z-index: 999;
  }
  .customer-close-btn {
    float: right;
    margin: 10px;
    border-radius: 50%;
    border: none;
    height: 30px;
    width: 30px;
  }
  .customer-prev-btn {
    margin: 10px 25% 0 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #bbbbbb;
  }
  .customer-next-btn {
    float: right;
    margin: 10px 10px 10px 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #bbbbbb;
  }
  .customer-add-update-div {
    color: white;
    display: flex;
    justify-content: right;
  }
  .customer-Modal-btn {
    font-size: 16px;
    background: #ad2128;
    border: 2px solid #ad2128;
    height: 35px;
    min-width: 80px;
    color: white;
    border-radius: 4px;
    margin: 0 7px 0 0;
    border: none;
  }
  .customer-Modal-btn:hover {
    box-shadow: 0 3px 5px rgb(40 36 51 / 50%);
    cursor: pointer;
  }
  .css-13cymwt-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    width: 100%;
  }
  .CustomerMangement_checkboxDiv {
    display: flex;
    margin: 15px 10px;
  }
  
  .checkboxDivContainer {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
  }
  .custom-input {
    border: none;
    outline: none;
    background: none;
    width: 100%;
    padding: 0;
    font-size: inherit;
  }
  .CustomerMangementCheckbox {
    margin: 0 15px;
  }
  .Tab-CustomerMangement {
    border: 0 solid #eee;
    height: 40px;
    display: flex;
    margin: 6px;
    border-radius: 5px;
    cursor: context-menu;
    background: #eef1ff;
  }
  .Tab-CustomerMangement li {
    display: block;
    padding: 10px 23px;
    cursor: pointer;
  }
  
  .Tab-CustomerMangement li.active {
    border-bottom: 1px solid #1c12db;
  }
  .customer-modal-title {
    text-align: center;
    color: #546271;
  }
  .contactInputFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .customer-add-button-container {
    text-align: end;
    margin: 0 1% 0 1%;
  }
  .customer-modal-open-btn {
    font-size: large;
    background: #ad2128;
    height: 35px;
    width: 100px;
    border: 0px solid #ad2128;
    color: white;
    border-radius: 4px;
    padding: 6px;
    line-height: 0.2;
    letter-spacing: 0.25px;
    margin: 0.3rem;
  }
  .customer-modal-open-btn:hover {
    box-shadow: 0 3px 5px rgb(40 36 51 / 50%);
    cursor: pointer;
  }
  .customer-inputfields {
    display: grid;
    width: 93%;
    padding: 10px;
  }
  .customer-label-class {
    color: #546271;
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
    width: 90%;
  }
  .customer-textbox-input {
    width: 95%;
    font-weight: 400;
    height: 35px;
    padding: 0 0 0 10px;
    border: 1px solid rgb(201, 200, 200);
    border-radius: 5px;
  }
  
  .customer-textbox-input:focus {
    outline: 1px solid rgb(201, 200, 200);
  }
  
  .customer-textbox-input:hover {
    box-shadow: 0 0 3px grey;
  }
  
  .customer-status-checkBoxes {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    flex-wrap: wrap;
  }
  .customer-checkbox {
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    min-height: 35px;
  }
  .customer-modalCheckbox {
    margin: 0 25px 0 15px;
  }
  .customer-checkBox-label-class {
    color: #546271;
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
  }
  .customer-add-btn-div {
    text-align: end;
    margin: 0 10px 0 0;
  }
  
  .select-dropdown {
    width: 96.5%;
  }
  
  .customer-container {
    margin: 0 1% 0 1%;
  }
  .customer-pwd-Inputsfields {
    display: grid;
    width: 93%;
    padding: 10px;
    position: relative;
  }
  .customer-pwd-container {
    cursor: pointer;
    position: absolute;
    right: 28px;
    height: 15px;
    top: 47px;
  }
  