.userAccount {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5% 0 5% 0;
  background: rgb(227, 226, 226);
  margin: 10px;
}

.left_Container {
  text-align: center;
  color: black;
}
.pwd-container {
  cursor: pointer;
  position: absolute;
  right: 10px;
  height: 15px;
  top: 20px;
}

.login_Form {
  text-align: center;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 0 0 0 15%;
}

.Loginlabel {
  text-align: left;
  margin-bottom: 10px;
}

.login_Heading {
  margin-bottom: 15px;
}

.login_form {
  display: flex;
  flex-direction: column;
}

.login_input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login_button {
  margin: 5px;
}
