.myProductContainer {
  background: white;
  margin: 0 3% 1% 3%;
  min-height: 500px;
}

.myProductHeading {
  margin: 0px;
  padding: 1% 10px;
}

.myProductImage {
  height: 50px;
  width: 50px;
}
