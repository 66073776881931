.ContactUs {
  background-color: white;
}

.ContactUs_top {
  border: 1px solid rgb(218, 218, 218);
  padding: 0 1% 2% 2%;
  border-radius: 4px;
  margin: 0 1.5% 0 1.5%;
}

.searchbar_button {
  display: flex;
  padding: 1% 1% 2% 0;
  align-items: center;
}

.serial_number {
  margin: 0 7% 0 0;
  border-radius: 5px;
  height: 35px;
  width: 30%;
}

.select_product_button {
  margin: 0 0 0 7%;
  width: 154px;
}

.contactus_elements {
  padding: 2% 0 2% 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.contactus_element {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 6%;
  padding: 1%;
  text-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgb(218, 218, 218);
  background: none;
  vertical-align: middle;
  min-width: 150px;
}
.element_image {
  height: 45px;
  padding-top: 3%;
}

.contactus_element:hover {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.29);
  border-radius: 4px;
}

.element_name {
  padding: 0%;
  margin: 0%;
}

.element_details {
  font-size: x-small;
}

.element_label {
  color: rgb(142, 142, 244);
}

.imageHedding {
  display: flex;
  align-items: center;
  width: 50%;
  padding-left: 1.5%;
}

.imageHedding_ {
  height: 25px;
  margin: 0 2% 0 0;
}

.ContactUs_Icon {
  height: 30px;
  font-size: 1.5em;
  margin: 1% 5px 0 0;
}

.ContactUs_Icons {
  height: 30px;
  font-size: xx-large;
  margin: 0 0 1% 0;
}

.contactUsNavLink {
  font-size: small;
}

@media only screen and (max-width: 600px) {
  .contactus_element {
    margin: 3% auto;
    width: 95%;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid rgb(218, 218, 218);
    font-size: 12px;
  }

  .contactus_elements {
    padding: 2% 0 0 0;
    display: flex;
    flex-wrap: wrap;
  }
}
