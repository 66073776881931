.input-checkbox {
  transform: scale(2);
  margin: 20px;
}
.image-uploader {
  border: 2px dashed #ccc;
  text-align: center;
  width: 95%;
  height: 213px;
  border-radius: 5px;
}
.file-input-label {
  height: 200px;
}
.uploadImageHeading {
  margin: 2% 0;
  color: #546271;
  font-weight: 600;
}
.image-uploader-heading {
  margin: 0%;
  padding: 3%;
  color: blue;
}
.dropped-files {
  flex-wrap: wrap;
  justify-content: center;
}
.file-info-image {
  margin: 5px;
}
.file-info {
  text-align: center;
  margin-left: 140%;
}
.delete-image-button-container {
  height: 25px;
  background-color: rgb(249, 103, 103);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 203px;
}
.delete-image-button {
  border: none;
  background: none;
  width: 100%;
  height: 25px;
  color: #fff;
}

.chechBox_labelclass {
  width: 30%;
  color: #546271;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
}

.productCategoryCheckbox {
  margin: 7px 0 0 0;
}

.imagesAndFields {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.productCategoryInputFields {
  min-width: 50%;
}

.modalWidthClass {
  min-width: 50%;
}

.imageUploaderDiv {
  text-align: center;
  width: 45%;
}

.driverAdd_button_container {
  text-align: end;
}

.driverModal-open-btn {
  font-size: 16px;
  background: #ad2128;
  height: 35px;
  width: 100px;
  border: 0px solid #ad2128;
  color: white;
  border-radius: 4px;
  padding: 6px;
  line-height: 0.2;
  letter-spacing: 0.25px;
  margin: 0.3rem;
}

.driverModal-open-btn:hover {
  box-shadow: 0 3px 5px rgb(40 36 51 / 50%);
  cursor: pointer;
}

.driverTable-body {
  padding: 0.3rem;
}

.driverTable-scroll {
  border: 3px solid #f9cbb5;
  border-radius: 10px;
}

.driverCheckBox_label-class {
  color: #546271;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
}

.driverDropdown-input {
  width: 98%;
  height: 35px;
}

.drivertextbox-input_1 {
  width: 95%;
  font-weight: 400;
  height: 35px;
}

.driverModal-open-btn{
  font-size: 16px;
  background: #ad2128;
  height: 35px;
  width: 100px;
  border: 0px solid #ad2128;
  color: white;
  border-radius: 4px;
  padding: 6px;
  line-height: 0.2;
  letter-spacing: 0.25px;
  margin: 0.3rem;
}

.driverModal-open-btn:hover {
  box-shadow: 0 3px 5px rgb(40 36 51 / 50%);
  cursor: pointer;
}

.driverModalCheckbox {
  margin: 0 25px 0 15px;
}
.driver-container{
  margin: 0 1% 0 1%;
}