.popupUserLogin {
  z-index: 111 !important;
  display: block !important;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.pwd-container-account {
  cursor: pointer;
  position: absolute;
  right: 10px;
  height: 15px;
  top: 40px;
}
.popup-acc-input-pwd {
  display: grid;
  padding: 10px 0 0;
  position: relative;
}
.userLoginPopupInnerDiv {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  width: 70% !important;
  position: absolute !important;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.popupUserAccount {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 5% 0 5% 0;
  background: rgb(227, 226, 226);
}

.popupLeft_Container {
  text-align: center;
  color: black;
}

.popupLogin_Form {
  text-align: center;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 0 0 0 15%;
}

.popupLoginlabel {
  text-align: left;
  margin-bottom: 10px;
}

.popupLogin_Heading {
  margin-bottom: 15px;
}

.popupLogin_form {
  display: flex;
  flex-direction: column;
}

.popupLogin_input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.popupLogin_button {
  margin: 5px;
}
.pwd-container-accountpopup {
  cursor: pointer;
  position: absolute;
  right: 10px;
  height: 15px;
  top: 48px;
}
