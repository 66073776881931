.App_footer {
  text-align: center;
  background-color: rgb(241, 240, 240);
  padding: 5px;
  grid-area: footer;
}

.content-wrapper {
  background: #888888;
  width: 100%;
  background-color: #eeeeee;
  color: #000;
  flex-grow: 1;
}
.page-body-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  transition: width 0.25s ease, margin 0.25s ease;
}
.admin-body-wrapper {
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  flex: 1 1 auto;
  border-radius: 5px !important;
  box-shadow: 3px 5px 25px 0 rgb(0 0 0 / 10%);
  margin: 5px;
  min-height: 86.5vh;
  background: #fff;
  overflow-y: auto;
}
.container-scroller {
  overflow: hidden;
  display: flex;
  position: relative;
  background-color: initial;
}

.main-panel {
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: 21vh;
  background: #e7eff0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
