.myOrdersHeading {
    padding: 0 0 0 3%;
  }
  
  .MyOptionContainer {
    background-color: white;
  }
  
  .myOrdersProduct {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 5%;
  }
  
  .orderImage {
    height: 120px;
    width: auto;
  }
  
  .Collapsible__trigger div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .collapsableTableContainer {
    overflow-y: auto;
  }
  
  .MyOrderTableContainer {
    overflow-y: auto;
    background: white;
    min-height: 500px;
  }
  
  .myOrdersTable {
    border: 2px solid #f9cbb5;
    border-radius: 4px;
    margin: 1% 3%;
    overflow: auto;
  }
  
  .orderImage {
    height: 100px;
    width: auto;
  }
  
  .css-1ex1afd-MuiTableCell-root {
    border-top: 1px solid rgba(224, 224, 224, 1);
  }
  
  .css-15wwp11-MuiTableHead-root {
    font-weight: bolder;
  }
  
  .noOrdersDiv {
    height: 30vh;
    display: flex;
    width: 100%;
    align-items: center;
  }
  
  .NoOrderHeading {
    text-align: center;
    width: 100%;
  }
  .order-product-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .order-product-inner-container {
    display: grid;
  }
  
  .productModalorder {
    z-index: 111 !important;
    display: block !important;
    position: fixed;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .modalContainerorder {
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    width: 85% !important;
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
  .headerModalorder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px;
    color: black;
    background-color: #f9cbb5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
    font-weight: 800;
  }
  .headerTitleorder {
    font-size: 14px;
    margin: 0;
    font-weight: 500;
  }
  .divDisplayorder {
    display: grid;
    width: 93%;
    padding: 10px;
  }
  .labelClassorder {
    color: #546271;
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
    width: 90%;
  }
  .textboxInputorder_1 {
    width: 95%;
    font-weight: 400;
    height: 35px;
  }
  .textInputorder {
    padding: 0 0 0 10px;
    border: 1px solid rgb(201, 200, 200);
    border-radius: 5px;
  }
  
  .textInputorder:focus {
    outline: 1px solid rgb(201, 200, 200);
  }
  
  .textInputorder:hover {
    box-shadow: 0 0 3px grey;
  }
  .footerModalorder {
    color: white;
    padding: 15px 0px 15px 0px;
    display: flex;
    justify-content: center;
  }
  .closeBtnorder {
    font-size: 16px;
    height: 35px;
    min-width: 80px;
    border: 2px solid #ad2128;
    color: black;
    border-radius: 4px;
    background: none;
  }
  .closeBtnorder:hover {
    box-shadow: 0 3px 5px rgb(40 36 51 / 50%);
    cursor: pointer;
  }
  .my-orders-view {
    font-size: 16px;
    background: #ad2128;
    border: 2px solid #ad2128;
    height: 35px;
    min-width: 80px;
    color: white;
    border-radius: 4px;
    margin: 10px;
    border: none;
  }
  .my-orders-view:disabled {
    background: #dddddd;
  }
  